<template>
  <el-row v-if="approveProcessInfo && approveProcessInfo.approves">
    <div class="approve-process zt-block">
      <el-steps direction="vertical" :active="active" :space="60">
        <el-step icon="iconfont icon-dggfg1">
          <template slot="title">
            <el-row type="flex" :gutter="24">
              <el-col :span="4">南康区工业设计中心</el-col>
              <el-col :span="2" v-if="approve1">
                <span v-if="approve1.state === 1">审批通过</span>
                <span v-if="approve1.state === 2">审批不通过</span>
                <br />
                <span
                  :class="[
                    {
                      cause: true,
                      failure: approve1.state === 2,
                      success: approve1.state === 1,
                    },
                  ]"
                  >{{ approve1.cause }}</span
                >
              </el-col>
              <el-col :span="4" v-if="approve1">{{
                approve1.approvedAt | moment("YYYY-MM-DD HH:mm:ss")
              }}</el-col>
            </el-row>
          </template>
          <template slot="description">
            <el-row type="flex" :gutter="24" v-if="approve1 && approve1.detail">
              <el-col :span="5">
                指定代表产品实物:
                <span style="color: #67c23a"
                  >{{ approve1.detail.item }}
                </span></el-col
              >
            </el-row>
          </template>
        </el-step>
        <el-step icon="iconfont icon-dggfg1">
          <template slot="title">
            <el-row type="flex" :gutter="24">
              <el-col :span="4">南康转企升规办</el-col>
              <el-col :span="2" v-if="approve2 && approve2.state === 1"
                >审批通过</el-col
              >
              <el-col :span="2" v-if="approve2 && approve2.state === 2"
                >审批不通过</el-col
              >
              <el-col :span="4" v-if="approve2">{{
                approve2.approvedAt | moment("YYYY-MM-DD HH:mm:ss")
              }}</el-col>
            </el-row>
          </template>
        </el-step>
        <el-step icon="iconfont icon-dggfg1">
          <template slot="title">
            <el-row type="flex" :gutter="24">
              <el-col :span="4">南康区家具产业促进局</el-col>
              <el-col :span="2" v-if="approve3">
                <span v-if="approve3.state === 1">审批通过</span>
                <span v-if="approve3.state === 2">审批不通过</span>
                <br />
                <span
                  :class="[
                    {
                      cause: true,
                      failure: approve3.state === 2,
                      success: approve3.state === 1,
                    },
                  ]"
                  >{{ approve3.cause }}</span
                >
              </el-col>
              <el-col :span="4" v-if="approve3">{{
                approve3.approvedAt | moment("YYYY-MM-DD HH:mm:ss")
              }}</el-col>
            </el-row>
          </template>
          <template slot="description">
            <el-row type="flex" :gutter="24" v-if="approve3 && approve3.detail">
              <el-col :span="5"
                >审批金额(万元):
                <span style="color: #67c23a">
                  {{ approve3.detail.amount }}
                </span>
              </el-col>
            </el-row>
          </template>
        </el-step>
        <el-step title="已归档" icon="iconfont icon-dggfg1"></el-step>
      </el-steps>
    </div>
  </el-row>
</template>
<script>
export default {
  props: {
    approveProcessInfo: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    // tofix
    active() {
      let current =
        this.approveProcessInfo && this.approveProcessInfo.currentNode;
      if (current) {
        if (current === "2501") {
          current = 0;
          if (this.approve1 && this.approve1.signedAt) {
            current = 1;
            if (this.approveProcessInfo.state === 3) {
              current = 4;
            }
          }
        } else if (current === "2502") {
          current = 1;
          if (this.approve2) {
            current = 2;
          }
          if (this.approveProcessInfo.state === 3) {
            current = 4;
          }
        } else if (current === "2503") {
          if (this.approveProcessInfo.state == 2) {
            current = 2;
          } else {
            if (this.approve3 && !this.approve3.signedAt) {
              current = 3;
            } else {
              current = 4;
            }
          }
        }
      } else {
        if (this.approve1 && this.approve1.state === 2) {
          current = 1;
        } else {
          current = 0;
        }
      }

      // 默认值是 0 step1 待流转状态
      // 1 step1 完成状态
      // 2 step2 完成状态
      // 3 step2 完成状态
      // 4 step2 完成状态
      return current;
    },
    approve1() {
      const item = this.approveList.find((item) => {
        return item.code === "2501";
      });
      return item;
    },
    approve2() {
      const item = this.approveList.find((item) => {
        return item.code === "2502";
      });
      return item;
    },
    approve3() {
      const item = this.approveList.find((item) => {
        return item.code === "2503";
      });
      return item;
    },
    approveList() {
      let list = this.approveProcessInfo && this.approveProcessInfo.approves;
      return list || [];
    },
  },
  filters: {
    depFilt(code) {
      const dataMap = [
        {
          label: "南康区工业设计中心",
          value: 2501,
        },
        {
          label: "南康转企升规办",
          value: 2502,
        },
        {
          label: "南康区家具产业促进局",
          value: 2503,
        },
      ];
      const obj = dataMap.find((item) => item.value === code);
      return obj && obj.label;
    },
  },
};
</script>
<style lang="less" scoped>
.approve-process {
  padding: 20px;
}

.cause {
  font-size: 12px;

  &.failure {
    color: tomato;
  }

  &.success {
    color: #67c23a;
  }
}
</style>